#root .react-calendar-timeline {
  min-width: 800px;
}

#root .react-calendar-timeline .rct-header-root {
  background-color: #303030;
}

#root .react-calendar-timeline .rct-dateHeader {
  background-color: transparent;
}

#root .rct-sidebar-row {
  line-height: normal !important;
  padding: 0;
}

#root .rct-outer {
  max-height: 70vh;
  overflow-y: scroll;
}

#root .rct-item {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#root .hovered-row {
  position: relative;
}

#root .hovered-row::after {
  background-color: rgba(219, 171, 255, 0.2);
  content: ' ';
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

#root .dzu-dropzone {
  overflow: auto;
  flex-direction: row;
  flex-wrap: wrap;
}

#root .dzu-dropzone .dzu-previewImage {
  max-height: 140px;
}
#root .dzu-dropzone .dzu-previewContainer {
  border: 0 none;
  flex-direction: column;
  padding: 16px;
  width: auto;
}

#root .dzu-dropzone .dzu-previewContainer .dzu-previewButton {
  display: none;
}

#root .dzu-dropzone .dzu-inputLabelWithFiles {
  display: none;
}

#root .MuiTableCell-head {
  white-space: nowrap;
}

#root .d-none {
  display: none;
}

.pdfobject-container {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 100;
}

#root .calendar .calendar-header table th.prev,
#root .calendar .calendar-header table th.next {
  width: 30vw;
}

#root .calendar .calendar-header table th.prev:hover,
#root .calendar .calendar-header table th.year-title:hover,
#root .calendar .calendar-header table th.next:hover {
  background-color: inherit;
}

#root .calendar .calendar-header table th.year-title:hover {
  cursor: default;
}

.calendar-context-menu,
.calendar-context-menu .submenu,
.calendar-context-menu .item .content:hover {
  color: black !important;
}
